<template>
    <section class="container h-100">
        <div class="row h-100 align-items-center">
            <div class="col-12 col-lg-6 text-white">
                <h1 class="text-left font-weight-light mt-5 mt-lg-0">
                    Acesse a nossa plataforma e construa um
                    <b class="font-weight-bold">atendimento personalizado!</b>
                </h1>
            </div>
            <div class="col-12 col-lg-6">
                <div class="card my-5">
                    <div class="card-body p-5 p-md-5">
                        <h4 class="title-border mb-4 text-left">
                            Faça seu login
                        </h4>

                        <validation-observer
                            ref="loginValidationForm"
                            tag="form"
                        >
                            <div class="row py-2">
                                <div class="col-12 my-2">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="email"
                                        rules="required"
                                    >
                                        <input
                                            type="email"
                                            placeholder="E-mail"
                                            class="form-control"
                                            :readonly="loading"
                                            :class="{
                                                'is-invalid': errors.length > 0,
                                            }"
                                            v-model="form.email"
                                        />
                                    </validation-provider>
                                </div>
                                <div class="col-12 my-2">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="senha"
                                        rules="required"
                                    >
                                        <div class="input-group">
                                            <input
                                                :type="
                                                    showPassword
                                                        ? 'text'
                                                        : 'password'
                                                "
                                                placeholder="Senha"
                                                class="form-control"
                                                :readonly="loading"
                                                :class="{
                                                    'is-invalid':
                                                        errors.length > 0,
                                                }"
                                                v-model="form.password"
                                            />
                                            <span
                                                class="input-group-text"
                                                role="button"
                                                @click="
                                                    showPassword = !showPassword
                                                "
                                            >
                                                <font-awesome-icon
                                                    style="width: 15.5px"
                                                    :icon="`fas ${
                                                        showPassword
                                                            ? 'fa-eye'
                                                            : 'fa-eye-slash'
                                                    }`"
                                                />
                                            </span>
                                        </div>
                                    </validation-provider>
                                </div>
                                <div class="col-12 my-2 text-left">
                                    <router-link
                                        custom
                                        :to="{ name: 'auth-forgot-password' }"
                                        v-slot="{ href, navigate }"
                                    >
                                        <a
                                            :href="href"
                                            @click="navigate"
                                            class="font-weight-bold"
                                        >
                                            Esqueci minha senha
                                        </a>
                                    </router-link>
                                </div>
                            </div>
                        </validation-observer>

                        <div class="row align-items-center secondary-colors">
                            <div class="col-12 col-lg-auto">
                                <button
                                    :disabled="loading"
                                    @click="submit"
                                    class="btn px-5 mr-lg-4 mt-3 w-100 w-lg-auto text-white"
                                >
                                    <span
                                        v-if="loading"
                                        class="spinner-border spinner-border-sm"
                                        role="status"
                                    ></span>
                                    <span v-else>Entrar</span>
                                </button>
                            </div>
                            <div class="col-12 col-lg-auto pt-3">
                                <router-link
                                    custom
                                    :to="{ name: 'auth-register' }"
                                    v-slot="{ href, navigate }"
                                >
                                    <a
                                        :href="href"
                                        @click="navigate"
                                        class="font-weight-bold"
                                    >
                                        Quero me cadastrar
                                    </a>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import api from "@/api";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            showPassword: false,
            required,
            form: {
                email: null,
                password: null,
                remember: true,
            },
            loading: false,
        };
    },
    methods: {
        toast(message, type = "danger") {
            this.$bvToast.toast(message, {
                title: "Entrar",
                variant: type,
            });
        },
        async submit() {
            const validated = await this.$refs.loginValidationForm.validate();

            if (!validated) return;

            this.loading = true;

            api.post("/users/login", this.form)
                .then((response) => {
                    const { type, message } = response.data;

                    if (type == "success") {
                        return this.$store
                            .dispatch("user/login", {
                                ...response.data.body,
                                remember: this.form.remember,
                            })
                            .then(() => {
                                const redirect = this.$route.query.redirect;

                                if (redirect) window.location = redirect;
                                else this.$router.go();
                            });
                    }

                    Object.assign(this.form, { password: null });
                    this.toast(message);
                })
                .catch((error) => {
                    var message =
                        "Não foi possível processar sua requisição corretamente. Tente novamente mais tarde.";
                    if (error.response) message = error.response.data.message;

                    this.toast(message);
                })
                .finally(() => (this.loading = false));
        },
    },
};
</script>

<style lang="scss" scoped>
a {
    color: #df6868;
}

button {
    background-color: #df6868;
    color: #fff;
}
</style>
